import React from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Footer from '../components/Footer'


function Homepage() {
  return (
    <div className='mt-16'>
        <Header/>
        {/* <Navbar/> */}
        <Hero/>
        {/* <Footer/> */}
        
    </div>
  )
}

export default Homepage